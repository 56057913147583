import { Typewriter } from "react-simple-typewriter";
import homeStyle from "./HomePage.module.css";
import { userProfile } from "../Components/UserProfile/UserData";

function HomePage(props) {
  // resize()
  return (
    <div className={homeStyle.homebody} id={props.id}>
      {/* <h3 style={{color: "white"}}>{size}</h3> */}
      <div className={homeStyle.container}>
        <div className={homeStyle.content}>
          <h2 className={homeStyle.greetings}>Hi,</h2>
          <p className={homeStyle.homename}>
            I'm <b>Adedoyin Idris</b>
          </p>
          <p className={homeStyle.profile}>
            I'm a{" "}
            <Typewriter
              cursor
              loop={Infinity}
              words={["Mobile Developer", "Data Scientist", "Web Developer"]}
            />
          </p>
        </div>
        <div className={homeStyle.homeDescContainer}>
          <p>{userProfile.homeDesc}</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
